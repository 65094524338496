import React, {Component} from 'react';
import axios from 'axios';
import '../config.js';

import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

class UserBasicInfo extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        id: props.id,
        search_users: [],
        supervisors: 0,
        name: props.user.name,
        email: props.user.email,
        mobile: props.user.mobile,
        supervisor: props.user.supervisor,
        supervisor_name: props.user.supervisor_name,
        address: props.user.address,
        role: props.user.role,        
        gender: props.user.gender,
        active: props.user.active,
        creation_date: props.user.creation_date,
        updation_date: props.user.updation_date,
        last_logged_in: props.user.last_logged_in,
        loading: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSupervisorChange = this.handleSupervisorChange.bind(this);
    this.supervisors = [];
  }
  
  componentDidMount(){
    this.fetchSupervisors();
  }
  
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  
  handleSupervisorChange(event) {
    const target = event.target;
    const value = target.value;
    let searchedUsers = [];
    let counter = 0;
    for(let i = 0;i<this.supervisors.length;i++){
      if(value.trim() != "" && this.supervisors[i].active && this.supervisors[i].name.indexOf(value) >= 0){
        searchedUsers.push(this.supervisors[i]);
        counter++;
      }
      if(counter == 5){
        break;
      }
    }
    this.setState({
      "search_users": searchedUsers
    });
  }
  
  handleSubmit(event) {
    event.preventDefault();
    if(this.state.name && this.state.email && this.state.mobile && this.state.role > 0){
      this.setState({loading: true});
      axios.post(global.config.api_server+'user',createFormData({
        name: this.state.name,
        email: this.state.email,
        mobile: this.state.mobile,
        address: this.state.address,
        role: this.state.role,
        gender: this.state.gender,
        active: this.state.active,
        company_id: this.state.company_id,
        supervisor: this.state.supervisor,
        plan: this.state.plan,
        token: localStorage.getItem("token"),
        user_id: this.state.id,
        task: 'basic'
      })).then(res => {
        this.setState({loading: false});
        if(res.status === 200 && res.data.error === undefined){
          alertService.success('User updated');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
        this.setState({loading: false});
      });
    }else{
      alertService.error('Some fields are required');
    }
  }
  
  fetchSupervisors(){
    axios.get(global.config.api_server+'users?token='+localStorage.getItem("token")+'&role=supervisor')
    .then(res => {
      if(res.status === 200){
        const users = res.data.users;
        if(users === null || users === undefined){
          alertService.error("Error fetching users");
        }else{
          this.supervisors = users;
          this.setState({
            "supervisors": users.length
          });
        }
      }
    }).catch(err => {
      alertService.error(err);
    });
  }

  render() {
    let roleSelection;
    if(parseInt(localStorage.getItem("role")) >= 5){
      roleSelection = <option value="5">Admin</option>;
    }
    return(
      <div className="card shadow mb-4">
        <div className="card-header py-3">            
          <button onClick={this.handleSubmit} className={'no-display btn btn-icon-split float-right btn-'+theme()}>
            <span className="icon text-white-50">
              <i className="fas fa-save"></i>
            </span>
            <span className="text">Save</span>
          </button>
          <h6 className={'mt-2 font-weight-bold text-'+theme()}>User - Basic Information</h6>
        </div>
        <div className="card-body">
          <div className="row">
            {this.state.loading === true ? (
              <Progress label='Saving' small={true}/>
            ):null}
            <div className="col-md-6">
              <div className="form-group">
                <label>Name</label>
                <input type="text" value={this.state.name} maxLength="50" name="name" className="form-control" id="name" placeholder="Enter Name" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Email</label>
                <input type="email" value={this.state.email} maxLength="50" name="email" className="form-control" id="email" placeholder="Enter Email" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Mobile</label>
                <input type="text" value={this.state.mobile} maxLength="15" name="mobile" className="form-control" id="mobile" placeholder="Enter Mobile" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Gender</label>
                <select name="gender" className="form-control" value={this.state.gender} id="gender" onChange={this.handleInputChange}>
                  <option>--Select--</option>
                  <option value="1">Male</option>
                  <option value="0">Female</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Status</label>
                <select name="active" className="form-control" value={this.state.active} id="active" onChange={this.handleInputChange}>
                  <option value="false">In-active</option>
                  <option value="true">Active</option>
                </select>
              </div>
              <div className="form-group">
                <label>Role</label>
                <select name="role" className="form-control" value={this.state.role} id="role" onChange={this.handleInputChange}>
                  <option value="0">None</option>
                  <option value="1">CRC</option>
                  <option value="2">Intern</option>
                  <option value="3">Executive</option>
                  <option value="4">Manager</option>
                  {roleSelection}
                </select>
              </div>
              <div className="form-group">
                <label>Address</label>
                <textarea value={this.state.address} maxLength="200" name="address" className="form-control" id="address" placeholder="Enter Address" onChange={this.handleInputChange}/>
              </div>
            </div>
          </div>
          <div className="row no-display">
            <div className="col-md-12">
              <hr/>
              <div className="form-group">
                <label><strong>Supervisor</strong></label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input type="text" disabled={true} value={this.state.supervisor_name} className="form-control"/>
                <hr/>
                <label>Set New Supervisor</label>
                <input type="text" maxLength="15" className="form-control" placeholder="Search Supervisor" onChange={this.handleSupervisorChange}/>
              </div>
            </div>
            <div className="col-md-6">
              {this.state.search_users.map(search_user =>
                <div className='form-group'key={search_user.user_id}>
                  <input className='mr-2' type="radio" value={search_user._id} maxLength="50" name="supervisor" id="supervisor" onChange={this.handleInputChange}/>
                  <label>{search_user.name} ({search_user.email})</label>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr/>
              <div className="form-group">
                <label><strong>Stats</strong></label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Last Logged-In</label>
                <input type="text" disabled={true} value={this.state.last_logged_in} className="form-control"/>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Updated</label>
                <input type="text" disabled={true} value={this.state.updation_date} className="form-control"/>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Created</label>
                <input type="text" disabled={true} value={this.state.creation_date} className="form-control"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UserBasicInfo;