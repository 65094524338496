import React, {Component} from 'react';
import axios from 'axios';
import '../config.js';

import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

class UserAdditionalInfo extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        id: props.id,
        dob: props.user.dob,
        blood_group: props.user.blood_group,
        father: props.user.father,
        mother: props.user.mother,
        emergency: props.user.emergency,
        emergency_mobile: props.user.emergency_mobile,
        personal_email: props.user.personal_email,
        personal_mobile: props.user.personal_mobile,        
        pan: props.user.pan,
        bank: props.user.bank,
        bank_branch: props.user.bank_branch,
        bank_account: props.user.bank_account,
        ifsc_code: props.user.ifsc_code,
        identity: props.user.identity,
        remark: props.user.remark,
        loading: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.supervisors = [];
  }
  
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  
  handleSubmit(event) {
    event.preventDefault();
    this.setState({loading: true});
    axios.post(global.config.api_server+'user',createFormData({
      dob: this.state.dob,
      blood_group: this.state.blood_group,
      father: this.state.father,
      mother: this.state.mother,
      emergency: this.state.emergency,
      emergency_mobile: this.state.emergency_mobile,
      personal_email: this.state.personal_email,
      personal_mobile: this.state.personal_mobile,        
      pan: this.state.pan,
      bank: this.state.bank,
      bank_branch: this.state.bank_branch,
      bank_account: this.state.bank_account,
      ifsc_code: this.state.ifsc_code,
      identity: this.state.identity,
      remark: this.state.remark,
      token: localStorage.getItem("token"),
      user_id: this.state.id,
      task: 'additional'
    })).then(res => {
      this.setState({loading: false});
      if(res.status === 200 && res.data.error === undefined){
        alertService.success('User updated');
      }else{
        alertService.error(res.data.error);
      }
    }).catch(err => {
      alertService.error(err);
      this.setState({loading: false});
    });
  }

  render() {
    return(
      <div className="card shadow mb-4">
        <div className="card-header py-3">            
          <button onClick={this.handleSubmit} className={'no-display btn btn-icon-split float-right btn-'+theme()}>
            <span className="icon text-white-50">
              <i className="fas fa-save"></i>
            </span>
            <span className="text">Save</span>
          </button>
          <h6 className={'mt-2 font-weight-bold text-'+theme()}>User - Additional Information</h6>
        </div>
        <div className="card-body">
          <div className="row">
            {this.state.loading === true ? (
              <Progress label='Saving' small={true}/>
            ):null}
            <div className="col-md-6">
              <div className="form-group">
                <label>Date of Birth</label>
                <input type="date" value={this.state.dob} maxLength="50" name="dob" className="form-control" id="dob" placeholder="Enter DOB" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Blood Group</label>
                <input type="text" value={this.state.blood_group} maxLength="5" name="blood_group" className="form-control" id="blood_group" placeholder="Enter Blood Group" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Father Name</label>
                <input type="text" value={this.state.father} maxLength="50" name="father" className="form-control" id="father" placeholder="Enter Father Name" onChange={this.handleInputChange}/>
              </div>
                <div className="form-group">
                <label>Mother Name</label>
                <input type="text" value={this.state.mother} maxLength="50" name="mother" className="form-control" id="mother" placeholder="Enter Mother Name" onChange={this.handleInputChange}/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Emergency Contact Name</label>
                <input type="text" value={this.state.emergency} maxLength="50" name="emergency" className="form-control" id="emergency" placeholder="Enter Emergency Contact" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Emergency Contact Number</label>
                <input type="text" value={this.state.emergency_mobile} maxLength="50" name="emergency_mobile" className="form-control" id="emergency_mobile" placeholder="Enter Emergency Contact Number" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Personal Email</label>
                <input type="email" value={this.state.personal_email} maxLength="50" name="personal_email" className="form-control" id="personal_email" placeholder="Enter Personal Email" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Personal Mobile</label>
                <input type="text" value={this.state.personal_mobile} maxLength="15" name="personal_mobile" className="form-control" id="personal_mobile" placeholder="Enter Personal Mobile" onChange={this.handleInputChange}/>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Identity Proof Document Name & Number</label>
                <input type="text" value={this.state.identity} maxLength="50" name="identity" className="form-control" id="identity" placeholder="Passport - xxxxxx" onChange={this.handleInputChange}/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr/>
              <div className="form-group">
                <label><strong>Banking Details</strong></label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Pan Card</label>
                <input type="text" value={this.state.pan} maxLength="20" name="pan" className="form-control" id="pan" placeholder="Enter Pan Card" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Bank Name</label>
                <input type="text" value={this.state.bank} maxLength="50" name="bank" className="form-control" id="bank" placeholder="Enter Bank Name" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Bank Branch</label>
                <input type="text" value={this.state.bank_branch} maxLength="50" name="bank_branch" className="form-control" id="bank_branch" placeholder="Enter Bank Branch" onChange={this.handleInputChange}/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Bank Account</label>
                <input type="text" value={this.state.bank_account} maxLength="20" name="bank_account" className="form-control" id="bank_account" placeholder="Enter Bank Account" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Bank IFSC Code</label>
                <input type="text" value={this.state.ifsc_code} maxLength="50" name="ifsc_code" className="form-control" id="ifsc_code" placeholder="Enter Bank IFSC Code" onChange={this.handleInputChange}/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr/>
              <div className="form-group">
                <label>Remark</label>
                <textarea value={this.state.remark} maxLength="200" name="remark" className="form-control" id="remark" placeholder="Enter Remark" onChange={this.handleInputChange}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UserAdditionalInfo;