import React, {Component} from 'react';

class Contact extends Component {

  componentDidMount(){

  }

  render() {
    return(
      <div>
        <h1>Contact</h1>
      </div>
    )
  }
}

export default Contact;