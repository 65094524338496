import React, {Component} from 'react';
import axios from 'axios';

import { createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import {Alert} from '../components/alert.js';

class Login extends Component{
  constructor(props) {
    super(props);
    this.handleOTPSubmit = this.handleOTPSubmit.bind(this);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.form = {};
    this.state = {
      loading: false,
      otpGenerated: false
    }
  }
  
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.form[name] = value;
  }
  
  handleOTPSubmit(event) {
    event.preventDefault();
    this.setState({loading: true});
    axios.get(global.config.api_server+'login?user='+this.form.user).then(res => {
      this.setState({loading: false});
      if(res.status === 200 && res.data.error === undefined && res.data.user){
        alertService.success("OTP send");
        this.form.userId = res.data.user;
        this.setState({otpGenerated: true});
      }else if(res.data.error !== undefined){
        alertService.error(res.data.error);
      }
    }).catch(error => {
      alertService.error(error);
      this.setState({loading: false});
    });
  }
  
  handleLoginSubmit(event) {
    event.preventDefault();
    const loginInfo = createFormData({
      user: this.form.userId,
      passcode: this.form.passcode
    });
    this.setState({loading: true});
    axios.post(global.config.api_server+'login',loginInfo).then(res => {
      this.setState({loading: false});
      if(res.status === 200 && res.data.error === undefined && res.data.user){
        localStorage.setItem('token', res.data.user.token);
        localStorage.setItem('name', res.data.user.name);
        localStorage.setItem('email', res.data.user.email);
        localStorage.setItem('mobile', res.data.user.mobile);
        localStorage.setItem('role', res.data.user.role);
        localStorage.setItem('user_id', res.data.user.user_id);
        localStorage.setItem('supervisor', res.data.user.supervisor);
        localStorage.setItem('theme', res.data.user.theme);
        window.location.reload();
      }else if(res.data.error !== undefined){
        alertService.error(res.data.error);
      }
    }).catch(error => {
      alertService.error(error);
      this.setState({loading: false});
    });
  }

  render(){
    return(
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                      </div>
                      <Alert />
                      {this.state.otpGenerated === false ? (
                      <form className="otp" onSubmit={this.handleOTPSubmit}>
                        <div className="form-group">
                          <input type="text" maxLength="50" name="user" className="form-control form-control-user" id="user" placeholder="Enter Email/Mobile" onChange={this.handleInputChange}/>
                        </div>
                        <input className="btn btn-primary btn-user btn-block" type="submit" value="Send OTP"/>
                      </form>
                      ):null}
                      {this.state.otpGenerated === true ? (
                      <form className="login" onSubmit={this.handleLoginSubmit}>
                        <div className="form-group">
                          <input type="text" maxLength="50" name="passcode" className="form-control form-control-user" id="passcode" placeholder="Enter OTP" onChange={this.handleInputChange}/>
                        </div>
                        <input className="btn btn-primary btn-user btn-block" type="submit" value="Login"/>
                      </form>
                      ):null} 
                      <div className="progress progress-sm mb-2 mt-3">
                      {this.state.loading === true ? (
                        <div className={'progress-bar progress-bar-striped progress-bar-animated'} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                      ):null}
                      </div>                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Login;