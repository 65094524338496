import React from 'react';
import { Link } from 'react-router-dom';

function NavAdmin() {

  return(
          <div>
            <hr className="sidebar-divider"/>
            <div className="sidebar-heading">
              Administrator
            </div>
            <li className="nav-item">
              <Link className="nav-link" to="/users"><i className="fas fa-fw fa-users"></i>Users</Link> 
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/users/client"><i className="fas fa-fw fa-users"></i>Clients</Link> 
            </li>
          </div>
  )
}

export default NavAdmin;