import React, {Component} from 'react';
import axios from 'axios';
import '../config.js';

import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

class UserProfile extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        id: props.user_id,
        name: props.user.name,
        email: props.user.email,
        mobile: props.user.mobile,
        address: props.user.address,
        enroll: props.user.enroll,
        
        gender: props.user.gender,
        pan: props.user.pan,
        bank: props.user.bank,
        bank_branch: props.user.bank_branch,
        bank_account: props.user.bank_account,
        account_holder_name: props.user.account_holder_name,
        dob: props.user.dob,
        ifsc_code: props.user.ifsc_code,
        identity: props.user.identity,
        loading: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  
  componentDidMount(){

  }
  
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    console.log(value);
    if(name === 'dob'){
      let dateValue = new Date(value);
      this.setState({
        [name]: dateValue
      });
    }else{
      this.setState({
        [name]: value
      });
    }
  }
  
  handleSubmit(event) {
    event.preventDefault();
    if(this.state.name && this.state.email && this.state.mobile && this.state.role > 0){
      this.setState({loading: true});
      axios.post(global.config.api_server+'user',createFormData({
        name: this.state.name,
        email: this.state.email,
        mobile: this.state.mobile,
        address: this.state.address,
        role: this.state.role,
        gender: this.state.gender,
        active: this.state.active,
        company_id: this.state.company_id,
        supervisor: this.state.supervisor,
        plan: this.state.plan,
        token: localStorage.getItem("token"),
        user_id: this.state.id,
        task: 'basic'
      })).then(res => {
        this.setState({loading: false});
        if(res.status === 200 && res.data.error === undefined){
          alertService.success('User updated');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
        this.setState({loading: false});
      });
    }else{
      alertService.error('Some fields are required');
    }
  }
  
  render() {
    const date = new Date(this.state.dob);
    let birth_date = date.getFullYear() + "-";
    if((date.getMonth() + 1) < 10){
      birth_date += "0";
    }
    birth_date += (date.getMonth() + 1);
    birth_date += "-";
    if(date.getMonth() < 10){
      birth_date += "0";
    }
    birth_date += date.getDate();
    return(
      <div className="card shadow mb-4">
        <div className="card-header py-3">            
          <button onClick={this.handleSubmit} className={'btn btn-icon-split float-right btn-'+theme()}>
            <span className="icon text-white-50">
              <i className="fas fa-save"></i>
            </span>
            <span className="text">Save</span>
          </button>
          <h6 className={'mt-2 font-weight-bold text-'+theme()}>My Profile</h6>
        </div>
        <div className="card-body">
          <div className="row">
            {this.state.loading === true ? (
              <Progress label='Saving' small={true}/>
            ):null}
            <div className="col-md-6">
              <div className="form-group">
                <label>Name</label>
                <input type="text" value={this.state.name} className="form-control" disabled={true}/>
              </div>
              <div className="form-group">
                <label>Mobile</label>
                <input type="text" value={this.state.mobile} className="form-control" disabled={true}/>
              </div>
              <div className="form-group">
                <label>Enrollment No.</label>
                <input type="text" value={this.state.enroll} className="form-control" disabled={true}/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Email</label>
                <input type="email" value={this.state.email} className="form-control" disabled={true}/>
              </div>
              <div className="form-group">
                <label>Address</label>
                <textarea value={this.state.address} maxLength="100" className="form-control" disabled={true}/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr/>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Gender</label>
                <select name="gender" className="form-control" value={this.state.gender} id="gender" onChange={this.handleInputChange}>
                  <option>--Select--</option>
                  <option value="1">Male</option>
                  <option value="0">Female</option>
                </select>
              </div>
              <div className="form-group">
                <label>Identity Proof and Detail</label>
                <textarea value={this.state.identity} maxLength="100" className="form-control" name="identity" placeholder="Enter ID Detail"/>
              </div>
              <div className="form-group">
                <label>Pan Card Number</label>
                <input type="text" value={this.state.pan} maxLength="15" name="pan" className="form-control" placeholder="Enter Pan Card No." onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Date of Birth</label>
                <input type="date" value={birth_date} name="dob" className="form-control" placeholder="Enter DOB" onChange={this.handleInputChange}/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Bank Name</label>
                <input type="text" value={this.state.bank} maxLength="20" name="bank" className="form-control" placeholder="Enter Bank Name" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Bank Branch</label>
                <input type="text" value={this.state.bank_branch} maxLength="50" name="bank_branch" className="form-control" placeholder="Enter Branch" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Bank Account No.</label>
                <input type="text" value={this.state.bank_account} maxLength="50" name="bank_account" className="form-control" placeholder="Enter Account No." onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Account Holder Name</label>
                <input type="text" value={this.state.account_holder_name} maxLength="50" name="account_holder_name" className="form-control" placeholder="Enter Account Holder Name" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>IFSC Code</label>
                <input type="text" value={this.state.ifsc_code} maxLength="15" name="ifsc_code" className="form-control" placeholder="Enter IFSC Code" onChange={this.handleInputChange}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UserProfile;