import React, {Component} from 'react';
import axios from 'axios';
import '../config.js';

import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';

class AdditionalProfile extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        id: props.user_id,
        supervisor_name: props.user.supervisor_name,
        supervisor_email: props.user.supervisor_email,
        supervisor_mobile: props.user.supervisor_mobile,
        
        creation_date: props.user.creation_date,
        updation_date: props.user.updation_date,
        last_logged_in: props.user.last_logged_in,
        
        theme: props.user.theme,
        blood_group: props.user.blood_group, 
        father: props.user.father,
        mother: props.user.mother, 
        emergency: props.user.emergency, 
        emergency_mobile: props.user.emergency_mobile, 
        personal_email: props.user.personal_email, 
        personal_mobile: props.user.personal_mobile, 
        marital_status: props.user.marital_status,
        religion: props.user.religion,
        loading: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  
  componentDidMount(){

  }
  
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    console.log(value);
    if(name === 'dob'){
      let dateValue = new Date(value);
      this.setState({
        [name]: dateValue
      });
    }else{
      this.setState({
        [name]: value
      });
    }
  }
  
  handleSubmit(event) {
    event.preventDefault();
    if(this.state.name && this.state.email && this.state.mobile && this.state.role > 0){
      this.setState({loading: true});
      axios.post(global.config.api_server+'user',createFormData({
        name: this.state.name,
        email: this.state.email,
        mobile: this.state.mobile,
        address: this.state.address,
        role: this.state.role,
        gender: this.state.gender,
        active: this.state.active,
        company_id: this.state.company_id,
        supervisor: this.state.supervisor,
        plan: this.state.plan,
        token: localStorage.getItem("token"),
        user_id: this.state.id,
        task: 'basic'
      })).then(res => {
        this.setState({loading: false});
        if(res.status === 200 && res.data.error === undefined){
          alertService.success('User updated');
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
        this.setState({loading: false});
      });
    }else{
      alertService.error('Some fields are required');
    }
  }
  
  render() {
    const date = new Date(this.state.dob);
    let birth_date = date.getFullYear() + "-";
    if((date.getMonth() + 1) < 10){
      birth_date += "0";
    }
    birth_date += (date.getMonth() + 1);
    birth_date += "-";
    if(date.getMonth() < 10){
      birth_date += "0";
    }
    birth_date += date.getDate();
    return(
      <div className="card shadow mb-4">
        <div className="card-header py-3">            
          <button onClick={this.handleSubmit} className={'btn btn-icon-split float-right btn-'+theme()}>
            <span className="icon text-white-50">
              <i className="fas fa-save"></i>
            </span>
            <span className="text">Save</span>
          </button>
          <h6 className={'mt-2 font-weight-bold text-'+theme()}>My Additional Info</h6>
        </div>
        <div className="card-body">
          <div className="row">
            {this.state.loading === true ? (
              <Progress label='Saving' small={true}/>
            ):null}
            <div className="col-md-4">
              <div className="form-group">
                <label>Supervisor</label>
                <input type="text" value={this.state.supervisor_name} className="form-control" disabled={true}/>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Supervisor Mobile</label>
                <input type="text" value={this.state.supervisor_mobile} className="form-control" disabled={true}/>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Supervisor Email</label>
                <input type="email" value={this.state.supervisor_email} className="form-control" disabled={true}/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr/>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Gender</label>
                <select name="theme" className="form-control" value={this.state.theme} onChange={this.handleInputChange}>
                  <option value="0">Blue</option>
                  <option value="1">Green</option>
                  <option value="2">Light Blue</option>
                  <option value="3">Orange</option>
                  <option value="4">Red</option>
                  <option value="5">Grey</option>
                  <option value="6">Dark</option>
                </select>
              </div>
              <div className="form-group">
                <label>Blood Group</label>
                <input type="text" value={this.state.blood_group} maxLength="5" name="blood_group" className="form-control" placeholder="Enter Blood Group" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Father Name</label>
                <input type="text" value={this.state.father} maxLength="50" name="father" className="form-control" placeholder="Enter Father Name" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Mother Name</label>
                <input type="text" value={this.state.mother} maxLength="50" name="mother" className="form-control" placeholder="Enter Mother Name" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Religion</label>
                <select name="religion" className="form-control" value={this.state.religion} onChange={this.handleInputChange}>
                  <option>--Select--</option>
                  <option value="Hinduism">Hinduism</option>
                  <option value="Islam">Islam</option>
                  <option value="Christianity">Christianity</option>
                  <option value="Sikhism">Sikhism</option>
                  <option value="Buddhism">Buddhism</option>
                  <option value="Jainism">Jainism</option>
                  <option value="Jainism">Jainism</option>
                  <option value="Atheism">Atheism</option>
                  <option value="Others">Others</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Emegency Contact Person Name</label>
                <input type="text" value={this.state.emergency} maxLength="50" name="emergency" className="form-control" placeholder="Enter Emegency Contact Name" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Emegency Mobile</label>
                <input type="text" value={this.state.emergency_mobile} maxLength="15" name="emergency_mobile" className="form-control" placeholder="Enter Emegency Mobile" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Personal Email</label>
                <input type="text" value={this.state.personal_email} maxLength="50" name="personal_email" className="form-control" placeholder="Enter Personal Email" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Personal Mobile</label>
                <input type="text" value={this.state.personal_mobile} maxLength="50" name="personal_mobile" className="form-control" placeholder="Enter Personal Mobile" onChange={this.handleInputChange}/>
              </div>
              <div className="form-group">
                <label>Marital Status</label>
                <select name="marital_status" className="form-control" value={this.state.marital_status} onChange={this.handleInputChange}>
                  <option>--Select--</option>
                  <option value="Married">Married</option>
                  <option value="Single">Single</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Widow">Widow</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <hr/>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Last Logged-In</label>
                <input type="text" disabled={true} value={this.state.last_logged_in} className="form-control"/>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Updated</label>
                <input type="text" disabled={true} value={this.state.updation_date} className="form-control"/>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Created</label>
                <input type="text" disabled={true} value={this.state.creation_date} className="form-control"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AdditionalProfile;