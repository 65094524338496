import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import '../config.js';

import { theme } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';

function UserList(props) {
  
  let statusClass = "text-gray-500";
  if(props.info.active){
    statusClass = "";
  }
  let role = "None";
  if(props.info.role == 1){
    role = "Client";
  }else if(props.info.role == 2){
    role = "Intern";
  }else if(props.info.role == 3){
    role = "Executive";
  }else if(props.info.role == 4){
    role = "Manager";
  }else if(props.info.role == 5){
    role = "Admin";
  }else if(props.info.role == 10){
    role = "Super Admin";
  }
  return(
        <tr className={statusClass}>
          <td>{props.info.name}</td>
          <td>{props.info.email}</td>
          <td>{props.info.mobile}</td>
          <td>{role}</td>
          <td>{props.info.status}</td>
          <td>
            <Link to={"/user/"+props.info.user_id} className={'btn btn-icon-split btn-'+theme()}>
              <span className="icon text-white-50">
                <i className="fas fa-edit"></i>
              </span>
              <span className="text">Edit</span>
            </Link>
          </td>
        </tr>
  )
}

export default UserList;