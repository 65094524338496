module.exports = global.config = {
    api_server: "https://hrms.insploreconsultants.com/v1/",
    //api_server: "http://localhost/v1/",
    filters: "None,Size,Category",
    placements: "None,Featured",
    docType: "Image,Main,PDF,Document,Download",
    status: ['Active',
      'Selected',
      'Rejected',
      'Suspended',
      'Terminated',
      'Warning',
      'Left',
    ],
    frequency: ['Once',
      'Weekly',
      'Monthly',
      'Annually'
    ],
};